import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import GenericResponseModel from 'src/app/common/models/generic-response.model';
import PagedResult from 'src/app/common/models/paged-result.model';
import { SortDirection } from 'src/app/common/models/sort-direction.enum';
import { BaseService } from 'src/app/common/services/base-http.service';

import { ArticleStock } from '../types/article-stock.model';
import {
  CreateStockTransaction,
  ListArticleStockTransaction,
  StockTransactionModel,
} from '../types/article-stock-transaction.models';
import UpdateArticleStockModel from '../types/update-article-stock.model';

@Injectable({ providedIn: 'root' })
export class ArticleStockService extends BaseService {
  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  public createStockTransaction(
    createStockTransaction: CreateStockTransaction,
    id: number,
  ): Observable<GenericResponseModel<StockTransactionModel>> {
    return this.httpClient.post<GenericResponseModel<StockTransactionModel>>(
      `/api/articles/${id}/stock-transactions`,
      createStockTransaction,
    );
  }

  public getArticleStockByArticleId(id: number): Observable<GenericResponseModel<ArticleStock>> {
    return this.httpClient.get<GenericResponseModel<ArticleStock>>(`/api/article/${id}/stock`);
  }

  public searchStockTransactions(
    id: number,
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortDirection?: SortDirection,
  ): Observable<PagedResult<ListArticleStockTransaction>> {
    const params = new URLSearchParams();
    params.append('page', (pageIndex + 1).toString());
    params.append('pageSize', pageSize.toString());

    if (sortField) {
      params.append('sortField', sortField);
    }

    if (sortDirection) {
      params.append('sortDirection', sortDirection);
    }

    return this.httpClient
      .get<GenericResponseModel<PagedResult<ListArticleStockTransaction>>>(
        `/api/articles/${id}/stock-transactions/search?${params}`,
      )
      .pipe(
        map(result => {
          return {
            data: result.data.data,
            totalCount: result.data.totalCount,
          };
        }),
      );
  }

  public updateArticleStock(
    id: number,
    updateArticleStockModel: UpdateArticleStockModel,
  ): Observable<GenericResponseModel<ArticleStock>> {
    return this.httpClient.put<GenericResponseModel<ArticleStock>>(`/api/article/${id}/stock`, updateArticleStockModel);
  }
}
